import React from 'react'
import Plx from 'react-plx'
import classNames from 'classnames'

import styles from '@components/icons/animated/moon.module.scss'

const Moon = ({ className, ...rest }) => {
  const plxData = [
    {
      start: 'self',
      startOffset: '30vh',
      duration: 200,
      name: 'opacity',
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
      ],
    },
    {
      start: 'self',
      startOffset: '50vh',
      duration: 200,
      name: 'movement',
      properties: [
        {
          startValue: 0,
          endValue: 50,
          property: 'translateX',
        },
      ],
    },
  ]

  return (
    <Plx className={styles.moonAnimation} parallaxData={plxData}>
      <svg
        viewBox="0 0 314.3 256.6"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames(styles.moonAnimation__svg, className)}
        {...rest}
      >
        <g>
          <path
            className="st0"
            d="M108.1,17.5c-2-1.7-0.6-5,2-4.8c0.5,0,1,0.1,1.4,0.1c15.1,1,29.6,5.5,43.8,10.5c24.5,8.6,45.4,21,60.2,43.1
          c27.5,41,21.5,99.1-7.4,137.7c-18.6,24.8-46.6,40.5-76.3,48.1c-7.5,1.9-15.3,3.6-23.1,4.4c-2.5,0.3-4-2.7-2.3-4.6
          c27.6-30.6,53.4-70.6,54.2-112.2c0.5-27.6-8.7-57.6-21.6-81.9C131.3,42.9,121,28.8,108.1,17.5C108.2,17.6,108.1,17.6,108.1,17.5z"
          />
          <polygon
            className="st0"
            points="69.5,86.4 52.3,82.2 60.9,98.8 52.3,116.6 72.5,108.6 85.5,121.2 88,104.4 102.3,92.7 82.5,89.7
          81.3,72.1 	"
          />
          <path
            className="st1"
            d="M32.9,114.4l-9.5-17.5c-0.3-0.6-1.2-0.5-1.5,0.1c-4.3,11.4-0.5,20.4-9.9,28.8c-1.5,1.4-7.8,5.2-11.6,7.3
          c-0.6,0.3-0.6,1.1,0,1.4c13.8,7.2,19.4,25.1,26.1,39.8c0.3,0.7,1.3,0.6,1.5-0.1c2-7.7,4-15.7,8-22.6c2.3-3.9,5.1-7.4,8.4-10.5
          c1.3-1.2,1.2-2.4,9.9-7.5c0.5-0.3,0.5-0.9,0.1-1.3C48.5,126.6,37.3,122.8,32.9,114.4z"
          />
          <path
            className="st1"
            d="M298.9,12.7l-6.7-12.4c-0.2-0.4-0.9-0.4-1,0.1c-3,8-0.4,14.4-7,20.3c-1.1,1-5.5,3.7-8.2,5.2
          c-0.4,0.2-0.4,0.8,0,1c9.7,5,13.7,17.7,18.4,28.1c0.2,0.5,0.9,0.4,1.1-0.1c1.4-5.4,2.8-11.1,5.6-15.9c1.6-2.8,3.6-5.2,6-7.4
          c0.9-0.9,0.8-1.7,6.9-5.3c0.3-0.2,0.4-0.6,0.1-0.9C309.9,21.3,302,18.6,298.9,12.7z"
          />
        </g>
      </svg>
    </Plx>
  )
}

export default Moon
