//TODO is there a good way to load these asynchronously?
import Music from '@components/icons/animated/music'
import Moon from '@components/icons/animated/moon'
import Rocket from '@components/icons/animated/rocket'
import RocketSingle from '@components/icons/animated/rocket-single'
import Leaves from '@components/icons/animated/leaves'
import Kite from '@components/icons/animated/kite'
import SeeSaw from '@components/icons/animated/see_saw'
import HighFive from '@components/icons/animated/high_five'
import Puzzle from '@components/icons/animated/puzzle'

export default {
  Music,
  Moon,
  Rocket,
  RocketSingle,
  Leaves,
  Kite,
  Puzzle,
  'See Saw': SeeSaw,
  'High Five': HighFive,
}
