import React from 'react'
import Plx from 'react-plx'

const Leaves = ({ className, ...rest }) => {
  const plxData = [
    {
      start: 'self',
      startOffset: '30vh',
      duration: 200,
      name: 'opacity',
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
      ],
    },
    {
      start: 'self',
      startOffset: '50vh',
      duration: 200,
      name: 'movement',
      properties: [
        {
          startValue: 0,
          endValue: 50,
          property: 'translateY',
        },
      ],
    },
  ]

  return (
    <Plx className="leavesAnimation animation" parallaxData={plxData}>
      <svg
        viewBox="0 0 195.2 232.2"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className={'leavesAnimation__svg ' + className}
        {...rest}
      >
        <g>
          <path
            className="st0"
            d="M67.8,15c-0.4,1.9-1.1,3.7-2.4,5.2c-4.6,5.6-11.7,6.7-18,6.8c-8.1,0-17.1-4-25.3-1.2c3.3,4.3,9.6,8.8,11.8,13
      		c6.3,11.9-2.6,20.8-11.5,27.6c-12,9.1-20,15.4-24.4,23.8c13,3.6,31.1-0.7,43.2,6c7.2,4,11.6,9.6,11.1,18c-0.3,5.9-2.9,8.8-4,17.1
      		c9-5.1,16.1-11,25.3-13.8c5.5-1.7,11.4-3.8,17.1-4.7c9.1-1.5,7.8,8.8,10.2,15.5c10.4-16.9,35-24.9,39.2-43.7
      		c3.2-14.3-1.2-28.2-10.3-35.6c3.4-11.3-0.7-25.3-11.4-35.3C103.6-0.4,73,1.8,61.9,0C63.4,5.6,68.9,9.4,67.8,15"
          />
          <path
            className="st1"
            d="M158.6,37.3c-0.9-0.5-1.9-0.6-2.8-0.3l-41.3,13L88.7,12.3c-0.6-0.8-1.4-1.4-2.4-1.5c-1-0.2-1.9,0-2.7,0.6
      		c-0.8,0.6-1.3,1.4-1.5,2.4c-0.2,1,0,2,0.6,2.8l24.4,35.8l-23.1,7.2L51.6,31.5c-1.5-1.3-3.8-1.1-5.2,0.4c-1.3,1.6-1.2,3.9,0.4,5.2
      		l28.9,25L24.3,78.3c-1.9,0.6-3,2.7-2.5,4.6c0.3,0.9,0.9,1.7,1.8,2.2c0.9,0.5,1.9,0.6,2.8,0.3l51.1-16l-13.7,40.8
      		c-0.7,1.9,0.4,4,2.3,4.7c1.9,0.7,4-0.4,4.7-2.3l15.4-45.9l22.9-7.2l-2.2,46.4c-0.1,2,1.5,3.8,3.5,3.8c2,0.1,3.7-1.5,3.8-3.6l2.3-49
      		l41.4-13c1.9-0.6,3-2.7,2.5-4.6C160.1,38.5,159.5,37.8,158.6,37.3z"
          />
          <path
            className="st1"
            d="M161,115.5c0.1-0.5,0.8-0.6,1-0.1c6.1,19.1,31.9,30,33.2,49.4c1.3,20.2-11.1,37.5-27.9,38.6
      		c-16.7,1.1-31.4-14.4-32.7-34.7C133.2,149.3,157.4,135.2,161,115.5"
          />
          <path
            className="st0"
            d="M144,185.5c0.5-0.9,1.7-1.3,2.7-0.7l17.7,9.9c-0.2-4.3-0.5-8.9-0.8-14l-16.6-9.3c-0.9-0.5-1.3-1.7-0.8-2.7
      		c0.5-0.9,1.7-1.3,2.7-0.7l14.4,8.1c-0.3-4.6-0.6-9.2-0.8-13.1l-10.9-6.1c-0.9-0.5-1.3-1.7-0.8-2.7c0.3-0.5,0.7-0.8,1.2-0.9
      		c0.5-0.1,1-0.1,1.5,0.2l8.7,4.9c-0.5-7.8-1-16.2-1.5-24.4c0-0.5,0.1-1,0.5-1.4c0.4-0.4,0.8-0.6,1.4-0.6c1.1-0.1,2,0.8,2.1,1.9
      		c0.4,8.2,1,16.5,1.5,24.3l8.8-7c0.4-0.3,0.9-0.5,1.5-0.4c0.5,0.1,1,0.3,1.3,0.7c0.7,0.8,0.5,2.1-0.3,2.8l-11,8.8
      		c0.2,3.8,0.5,8.3,0.8,12.8l14.2-11.3c0.4-0.3,0.9-0.5,1.5-0.4c0.5,0.1,1,0.3,1.3,0.7c0.7,0.8,0.5,2.1-0.3,2.8l-16.4,13
      		c0.3,5,0.5,9.4,0.8,13.7l17.3-13.8c0.4-0.3,0.9-0.5,1.5-0.4c0.5,0.1,1,0.3,1.3,0.7c0.7,0.8,0.5,2.1-0.3,2.8l-19.5,15.6
      		c0.1,1.7,0.2,3.4,0.2,5.1c0.2,4.1,0.2,8.6-0.7,13c-1.4,6.7-4.9,12.2-9.6,14.9c-0.3,0.2-0.6,0.3-1,0.3c-0.2,0-0.4,0-0.6-0.1
      		c-0.5-0.2-1-0.5-1.2-1c-0.5-0.9-0.1-2,0.8-2.6c3.8-2.2,6.6-6.7,7.8-12.3c0.8-4,0.8-8.1,0.6-12c-0.1-1.6-0.1-3.2-0.2-4.8l-19.9-11.1
      		C143.8,187.6,143.5,186.4,144,185.5"
          />
        </g>
      </svg>
    </Plx>
  )
}

export default Leaves
