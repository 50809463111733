import React from 'react'
import Plx from 'react-plx'
import classNames from 'classnames'

import styles from '@components/icons/animated/music.module.scss'

const Music = ({ className }) => {
  const fadeUpEffect = (_offset, rotation) => [
    {
      start: 'self',
      startOffset: '40vh',
      duration: 50,
      name: 'opacity',
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
        {
          startValue: rotation,
          endValue: rotation,
          property: 'rotate',
        },
      ],
    },
    {
      start: 'self',
      startOffset: '40vh',
      duration: 200,
      name: 'movement',
      properties: [
        {
          startValue: 0,
          endValue: -25,
          property: 'translateY',
        },
      ],
    },
  ]

  const plxData = {
    top: fadeUpEffect(0, -5),
    middle: fadeUpEffect(25, -20),
    bottom: fadeUpEffect(50, 20),
  }

  return (
    <div className={styles.musicAnimation}>
      <Plx
        className={classNames(styles.musicAnimation__part, styles.musicAnimation__noteTop)}
        parallaxData={plxData.top}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.5 144.5">
          <path
            className="note"
            d="M92.5 0s-10.8 7.8-26 7.2S38.4 0 38.4 0s1.5 41.3 1.2 58.3c-.3 20.6-5.7 45.5-5.7 45.5S12.7 93.9 4 106.2s-3.3 38.3 15.3 38.3 27.8-12 29-26.3 5.4-66.4 5.4-66.4 13.5 3.9 21.5 3c7.8-.9 12-2.4 12-2.4L92.5 0zM-174.8 27.9s4.4 1.7 12 2.1c8.2.4 12.7-2.1 12.7-2.1 0 10.3-.7 20.9-.7 31.3 0 3.6-.6 9.3-5.2 9.5-4 .2-8.1-4.4-7.4-8.3.7-3.5 3.8-5.1 7-5.4h.8c.2-.9.1-10 .3-10 0 0-3.4.3-6 .3-2.7-.1-9.7-1.1-9.7-1.1-.2 3-.4 6-.8 9-.4 3.3-.2 7-1.8 10-1.8 3.4-7.3 4.9-10.3 2-2-1.9-2.3-5.6-1-7.9 2.1-3.7 6-2.5 9-.9l1.1-28.5z"
          />
        </svg>
      </Plx>

      <Plx
        className={classNames(styles.musicAnimation__part, styles.musicAnimation__noteMiddle)}
        parallaxData={plxData.middle}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 126 144.5">
          <path
            className="note"
            d="M38.5 0S54.1 6 81 7.4C110 8.8 126 0 126 0c0 36.5-2.5 74-2.5 110.8 0 12.7-2.1 32.9-18.4 33.6-14.2.7-28.7-15.6-26.2-29.4 2.5-12.3 13.5-18 24.8-19h2.8c.7-3.2.4-35.4 1.1-35.4 0 0-12 1.1-21.2 1.1-9.6-.4-34.4-4-34.4-4-.7 10.6-1.4 21.2-2.8 31.9-1.4 11.7-.7 24.8-6.4 35.4-6.4 12-25.9 17.4-36.5 7.1-7.1-6.7-8.1-19.8-3.5-28 7.4-13.1 21.2-8.9 31.9-3.2L38.5 0z"
          />
        </svg>
      </Plx>

      <Plx
        className={classNames(styles.musicAnimation__part, styles.musicAnimation__noteBottom)}
        parallaxData={plxData.bottom}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.5 144.5">
          <path
            className="note"
            d="M92.5 0s-10.8 7.8-26 7.2S38.4 0 38.4 0s1.5 41.3 1.2 58.3c-.3 20.6-5.7 45.5-5.7 45.5S12.7 93.9 4 106.2s-3.3 38.3 15.3 38.3 27.8-12 29-26.3 5.4-66.4 5.4-66.4 13.5 3.9 21.5 3c7.8-.9 12-2.4 12-2.4L92.5 0zM-174.8 27.9s4.4 1.7 12 2.1c8.2.4 12.7-2.1 12.7-2.1 0 10.3-.7 20.9-.7 31.3 0 3.6-.6 9.3-5.2 9.5-4 .2-8.1-4.4-7.4-8.3.7-3.5 3.8-5.1 7-5.4h.8c.2-.9.1-10 .3-10 0 0-3.4.3-6 .3-2.7-.1-9.7-1.1-9.7-1.1-.2 3-.4 6-.8 9-.4 3.3-.2 7-1.8 10-1.8 3.4-7.3 4.9-10.3 2-2-1.9-2.3-5.6-1-7.9 2.1-3.7 6-2.5 9-.9l1.1-28.5z"
          />
        </svg>
      </Plx>
    </div>
  )
}

export default Music
