import React from 'react'

const Play = ({ className, color, ...rest }) => {
  return (
    <svg
      viewBox="0 0 45 45"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon ' + className}
      {...rest}
    >
      <g transform="translate(1 1)" strokeWidth="2" stroke="#00B9B4" fill="none" fillRule="evenodd">
        <path d="M15.67 11.54V32.5l17.77-10.48z" />
        <ellipse cx="21.42" cy="21.5" rx="21.42" ry="21.5" />
      </g>
    </svg>
  )
}

export default Play
