import React from 'react'
import classNames from 'classnames'

import styles from '@components/icons/file.module.scss'

const File = ({ className, color, ...rest }) => {
  return (
    <svg
      viewBox="0 0 36 45"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('icon', styles.fileIcon, className)}
      {...rest}
    >
      <g strokeWidth="2" fill="none" fillRule="evenodd">
        <path d="M1 1h30v39H1z" />
        <path
          d="M7.5 8h17M7.5 20h17M7.5 26h17M7.5 32h17M3 44h31M35 3v41M7.5 14h17"
          strokeLinecap="square"
        />
      </g>
    </svg>
  )
}

export default File
