import React from 'react'
import Plx from 'react-plx'

import styles from '@components/icons/animated/rocket.module.scss'

const Rocket = ({ className, ...rest }) => {
  const plxData = [
    {
      start: 'self',
      startOffset: '30vh',
      duration: 150,
      name: 'opacity',
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
      ],
    },
    {
      start: 'self',
      startOffset: '50vh',
      duration: 200,
      name: 'movement',
      properties: [
        {
          startValue: 0,
          endValue: -100,
          property: 'translateY',
        },
        {
          startValue: 0,
          endValue: 25,
          property: 'translateX',
        },
      ],
    },
  ]

  return (
    <Plx className={styles.rocketAnimation} parallaxData={plxData}>
      <svg
        viewBox="0 0 360 256.6"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className={'rocketAnimation__svg ' + className}
        {...rest}
      >
        <g>
          <path
            className={styles.st0}
            d="M210.3,96.6c0,0-15,13.3-18.3,34.5s-0.7,34.8,1.7,34.5c2.3-0.3,16.7-22.3,16.7-22.3V96.6z"
          />
          <path
            className={styles.st0}
            d="M264.3,108.5c0,0,10.8,16.9,8.1,38.2s-8.9,33.7-11.1,32.7c-2.2-1-9.9-26.1-9.9-26.1L264.3,108.5z"
          />
          <path
            className={styles.st1}
            d="M207.5,142.9c0.1,1.8,1,3.3,2.5,4.3c3.6,2.2,10.7,6,18.4,7.4c7.7,1.4,16,0.8,20.3,0.4c1.8-0.2,3.3-1.3,4.1-2.9
      		c4.4-9.3,19.2-42,19.6-68c0.4-25.6-9.7-58.6-13-68.5c-0.6-1.8-2.2-3.2-4.1-3.5l0,0c-1.8-0.3-3.5,0.3-4.7,1.6
      		c-7.4,8.3-34.1,38.7-39.2,56.4C206.1,88,207.1,131.6,207.5,142.9z"
          />
          <polygon
            className={styles.st2}
            points="151.8,20.1 159.3,0 167.3,20.1 183.8,20.1 170.8,33.1 178.8,54.1 158.3,45.6 139.9,54.6 146.8,35.1
      		137.8,22.1 	"
          />
          <polygon
            className={styles.st2}
            points="80.8,131.1 81.9,146.6 100.3,148.6 85.8,160.6 86.3,178.1 72.8,166.1 54.3,172.6 61.8,154.6 57,142.6
      		70.3,145.1 	"
          />
          <polygon
            className={styles.st2}
            points="30.8,229.6 24.8,211.1 15.9,225.6 0,224.6 9.9,238.1 2.8,256.6 20.8,251.1 37.3,256.6 33.3,242.6
      		45.3,233.8 	"
          />
          <polygon
            className={styles.st2}
            points="333.8,72.6 333.8,85.1 325.8,91.1 333.8,95.1 336.3,105.6 343.3,96.6 360,96.1 352.3,86.6 352.8,72.6
      		342.9,78.6 	"
          />
          <path
            className={styles.st2}
            d="M209.6,248.1L209.6,248.1c1.3,0.3,2.6-0.5,3-1.7l23.5-76.1c0.4-1.4-0.5-3-1.9-3.2l-15-2.9
      		c-1.5-0.3-2.9,0.7-3,2.2l-8.5,79C207.5,246.6,208.3,247.8,209.6,248.1z"
          />
          <circle className={styles.st0} cx="247.8" cy="59.8" r="7.7" />
          <circle className={styles.st0} cx="243.8" cy="86.8" r="7.7" />
        </g>
      </svg>
    </Plx>
  )
}

export default Rocket
