import React from 'react'
import Plx from 'react-plx'

import styles from '@components/icons/animated/kite.module.scss'

const Kite = ({ className, ...rest }) => {
  const plxData = [
    {
      start: 'self',
      startOffset: '50vh',
      duration: 150,
      name: 'opacity',
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
      ],
    },
    {
      start: 'self',
      startOffset: '50vh',
      duration: 200,
      name: 'movement',
      properties: [
        {
          startValue: 0,
          endValue: -100,
          property: 'translateY',
        },
        {
          startValue: 0,
          endValue: 25,
          property: 'translateX',
        },
      ],
    },
  ]

  return (
    <Plx className={styles.kiteAnimation} parallaxData={plxData}>
      <svg
        viewBox="0 0 165 232.7"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className={'kiteAnimation__svg ' + className}
        {...rest}
      >
        <g>
          <path
            className="st0"
            d="M12.1,232.7c7.7-2.6,14.5-7.9,18.7-14.8c4.6-7.5,6.3-16.4,8.9-24.8c2.7-8.4,6.9-17,14.7-21.2
        		c7.3-3.9,16.4-3.2,23.9-6.9c3.2-1.6,6-4,8.6-6.6c3.8-4,7.1-8.6,8.8-13.8c0.7-2.1,0.8-5-1.1-6c-2.4-1.3-4.9,1.4-6.3,3.7
        		c-3.3,5.3-7.1,10.9-12.9,13.2c-4.7,1.8-10,1.2-15,1.8c-8.6,1-16.7,5.9-21.5,13.1c-2.6,3.8-4.3,8.1-6.3,12.2
        		c-4.9,10-12.6,19.3-22.8,24c-2.4,1.1-5.8,1.2-8,2.5c-2.6,1.5-1.8,1.6-0.2,4.3C5.2,219.8,8.6,226.3,12.1,232.7z"
          />
          <polygon className="st1" points="123.9,0 165,66.6 94.4,146.8 63.5,43.7 	" />
          <polygon className="st0" points="63.6,43.8 94.4,146.8 165,66.6 164.9,66.5 	" />
          <polygon className="st0" points="123.3,0.8 63.5,44 63.6,44.2 112.7,55.2 	" />
          <path className="st1" d="M112.7,54.8l-49.2-11l30,100.1C98.9,119,109.8,68.3,112.7,54.8z" />
        </g>
      </svg>
    </Plx>
  )
}

export default Kite
