import React from 'react'
import Plx from 'react-plx'
import classNames from 'classnames'

import styles from '@components/icons/animated/puzzle.module.scss'

const Puzzle = () => {
  const plxData = {
    hand: [
      {
        start: 'self',
        startOffset: '30vh',
        duration: 150,
        name: 'opacity',
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: 'opacity',
          },
        ],
      },
      {
        start: 'self',
        startOffset: '50vh',
        duration: 93,
        name: 'movement',
        properties: [
          {
            startValue: 0,
            endValue: -40,
            property: 'translateY',
          },
          {
            startValue: 0,
            endValue: -30,
            property: 'translateX',
          },
        ],
      },
    ],
    spark: [
      {
        start: 'self',
        startOffset: '60vh',
        duration: 45,
        name: 'opacity',
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: 'opacity',
          },
        ],
      },
    ],
  }

  return (
    <div className={styles.puzzleAnimation}>
      <Plx
        className={classNames(styles.puzzleAnimation__part, styles.puzzleAnimationHand)}
        parallaxData={plxData.hand}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1040 900">
          <g>
            <path
              className={styles.st0}
              d="M890.5 346.6c2.1-2.4 3.7-4.9 7.7-5.1 8.2-.5 11 5.2 13.5 10.9 6.5 15.2 11.2 31.2 15.5 47.2 7.5 27.4 10.5 55.9 8.8 84.3-.9 18-3.3 36-7.2 53.7-2.5 11-4.2 22.1-2.4 33.4 1.2 7.9 3.6 15.6 5.7 23.3 2.8 9.8 5.7 19.6 8.5 29.4 4 11.9 8 23.8 11.9 35.6 1.8 5.5 33.1 118.3 35.4 123.6 2.8 6.6 1.8 12.7-1.1 18.8-7.4 15.8-18.6 28.8-30 41.6-10.8 12.1-22.5 23.5-34.2 34.7-5.5 5.3-11.3 10.4-17.3 15.1-3.4 2.5-7 4.5-10.9 6.1-4.7 2-7.5.8-9.3-4.1-2.1-5.8-3.7-11.7-5.6-17.6-.7-2.1-31.1-111.4-31.7-113.4l-7.2-28.3c-4.5-17.2-9.2-34.5-13.7-51.7-5.9-22.1-12-44.2-19-66-3.8-12-10-22-20.4-29.3-10.1-7.2-19.8-14.9-30.1-21.8-12.6-8.5-24.9-17.3-36.4-27.3-5.7-4.9-11.2-9.8-14.5-16.7-4.1-8.5-4.2-17.2 0-25.8 1.7-1.2 3.2-2.6 5.2-3.3 7.8-2.8 15-1.6 21.7 3.3 4.1 3 8 6.3 12.2 9.2 8 5.5 15.9 11 26.4 9.9 4.2-.4 7.3-2.3 8.3-6.5 1-3.5 1.2-7.1.7-10.6-1.1-6.7-3.4-13-5.7-19.3-7-19.4-14.6-38.5-21.8-57.8-4.2-11.3-8.5-22.5-10.8-34.4-.8-3.8-.8-7.8-2.3-11.5 0-1.6 0-3.3-.1-4.9-.5-5.7.6-10.5 5.1-14.8 4.2-3.9 9.9-5.3 14.8-1.9 4.4 3 8.1 7 10.9 11.5 5.5 9 10.1 18.4 13.8 28.3 1.9 4.5 3.8 9 5.7 13.4 5.9 13.5 11.6 27 18.3 40.1 1.8 3.5 2.8 7.7 6.1 10.4 1.4 1.2 3 2 4.8 1s1.7-2.9 1.2-4.7c-4.8-19.7-11.3-39-19.5-57.5-4.5-10.5-9.4-20.9-12.6-32-1.4-5-2.3-10.1-4.5-14.9 0-3.5-.2-7 0-10.4.5-7.6 7-16.2 16.4-12.7 4.9 1.7 9 5 11.8 9.3 7.5 11.9 13.2 24.7 18.1 37.8 5.1 13.6 9 27.7 14.9 41.1 1.8 4.2 3.6 8.4 5.5 12.6.5 3.6 2.3 6.8 3.5 10.2.7 1.9 1.9 3.8 4.2 3.4s2.9-2.7 2.8-4.9c-.5-6.7-1.6-13.3-3.4-19.7-5.5-22.4-11.4-44.8-18.8-66.7.5-4.3 0-8.8 2.2-12.8 4.1-7.8 12-10.1 18.3-3.4 4.6 4.9 8.1 10.8 10.2 17.2 6.6 19 11.3 38.6 16.6 58 2 7.2 3.3 14.6 6.9 21.3.3 1.5 1.7 2.6 3.3 2.3h.1c1.6-.2 2.2-1.5 2.5-3 .8-3 1.1-6.1 1-9.3-1.7-18.9-6.5-37-12.4-55-.3-6.4.5-12.8 2.4-18.9z"
            />
            <path
              className={styles.st1}
              d="M774.5 296.5c6.3-1.4 12.7-2.2 19.2-2.3 1.2 0 2.5-.2 3.8-.4 9.6.5 19.2 1.1 28.5 3.6 28.1 7.7 48.9 24.9 64.5 49.1-1.8 6.1-2.6 12.5-2.3 18.9-1.7 1-2.3 2.7-2.7 4.3-1.7 6.8-2.4 13.6-.3 20.5 3.2 10.2 7.1 20.3 8.2 31.1.3 3 2.3 6 .3 9-3.6-6.7-4.9-14.1-6.9-21.3-5.4-19.4-10.1-39-16.6-58-2.2-6.4-5.6-12.3-10.2-17.2-6.3-6.7-14.2-4.4-18.3 3.4-2.1 4-1.7 8.5-2.2 12.8-3.9 7.9-5.9 16.3-2.9 24.7 4.5 12.7 7.7 25.7 11.3 38.6 1.3 4.7 3.6 9.3 3.2 14.4-1.8-4.2-3.6-8.4-5.5-12.6-5.9-13.4-9.8-27.4-14.9-41.1-4.9-13.1-10.6-25.9-18.1-37.8-2.8-4.3-6.9-7.6-11.8-9.3-9.4-3.6-16 5-16.4 12.7-.2 3.5 0 7 0 10.4-2.6 2.5-4.2 6-4.2 9.7-.4 10.3 1.8 20.1 5 29.8.5 1.6 1.1 3.1-.3 4.5-3.7-9.8-8.4-19.3-13.8-28.3-2.7-4.6-6.5-8.5-10.9-11.5-4.9-3.4-10.6-2-14.8 1.9-4.5 4.2-5.5 9.1-5.1 14.8.1 1.6.1 3.2.1 4.9-1.9 3.1-4 6.2-4.2 10-.5 9.5 1.2 18.7 3.7 27.7 4.6 16.2 10.2 32.2 16.7 47.7 4.2 10 7.9 20.2 11.5 30.5 1.5 4.4 3.8 8.5 4.6 13.1.1.8.6 1.6 0 2.3-.8 1-1.7.1-2.5-.2-8.4-3-16.4-7-24-11.7-13.8-8.7-25.4-19.4-32.3-34.4-5.5-11.9-8.8-24.6-9.9-37.7-1.2-14.4-1-28.6 2.2-42.7 4.3-19.7 13.4-37.9 26.7-53.1 11-12.5 24.5-22.5 39.7-29.3.8-.2 1.9-.2 1.9-1.5z"
            />
            <path
              className={styles.st2}
              d="M774.5 296.5c0 1.3-1.2 1.3-2 1.6-15.2 6.8-28.7 16.8-39.7 29.3-13.2 15.2-22.4 33.4-26.7 53.1-3.2 14.1-3.4 28.4-2.2 42.7 1.1 13 4.4 25.8 9.9 37.7 7 15 18.6 25.7 32.3 34.4 7.6 4.7 15.6 8.6 24 11.7.8.3 1.7 1.2 2.5.2.6-.7.2-1.5 0-2.3-.8-4.6-3.1-8.7-4.6-13.1-3.6-10.2-7.3-20.4-11.5-30.5-6.6-15.5-12.2-31.5-16.7-47.7-2.5-9-4.2-18.2-3.7-27.7.2-3.9 2.3-6.9 4.2-10 1.6 3.7 1.6 7.7 2.3 11.5 2.4 11.9 6.6 23.2 10.8 34.4 7.2 19.3 14.8 38.5 21.8 57.8 2.3 6.3 4.6 12.6 5.7 19.3.5 3.6.3 7.2-.7 10.6-1 4.2-4.1 6.1-8.3 6.5-10.5 1.1-18.4-4.5-26.4-9.9-4.2-2.9-8.2-6.1-12.2-9.2-6.6-4.9-13.8-6.1-21.7-3.3-2 .7-3.5 2.1-5.2 3.3-4.9-5.2-9.5-10.7-13.6-16.6-9.1-15-14.2-31.3-16.4-48.5-1.2-8.9-1.1-17.8-.9-26.7 1.2-9.6 2.5-19.3 5.4-28.6 11.9-37.8 36.1-63.5 74.2-75.5 6.4-1.9 12.9-3.4 19.4-4.5z"
            />
            <path
              className={styles.st3}
              d="M784.9 394.1c1.4-1.4.8-3 .3-4.5-3.2-9.7-5.5-19.5-5-29.8.1-3.7 1.6-7.1 4.2-9.7 2.2 4.7 3.1 9.9 4.5 14.9 3.2 11.1 8 21.5 12.6 32 8.2 18.5 14.7 37.8 19.5 57.4.4 1.8.6 3.6-1.2 4.7s-3.4.2-4.8-1c-3.2-2.7-4.2-6.9-6.1-10.4-6.7-13.1-12.5-26.6-18.3-40.1l-5.7-13.5zM851.1 427.9c.4-5.1-1.9-9.7-3.2-14.4-3.6-12.9-6.8-25.9-11.3-38.6-3-8.4-1-16.8 2.9-24.7 7.4 21.9 13.3 44.2 18.8 66.7 1.8 6.5 2.9 13.1 3.4 19.7.1 2.2-.3 4.4-2.8 4.9s-3.6-1.5-4.2-3.4c-1.4-3.5-3.1-6.6-3.6-10.2zM893.7 430.4c2-3.1 0-6-.3-9-1.1-10.8-5-20.8-8.2-31.1-2.1-6.9-1.4-13.7.3-20.5.4-1.6 1-3.4 2.7-4.3 5.9 17.9 10.7 36.1 12.4 55 .1 3.1-.2 6.2-1 9.3-.3 1.5-1 2.7-2.5 3-1.5.3-3-.7-3.3-2.2l-.1-.2z"
            />
          </g>
        </svg>
      </Plx>

      <Plx
        className={classNames(styles.puzzleAnimation__part, styles.puzzleAnimationSpark)}
        parallaxData={plxData.spark}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1040 900">
          <g>
            <path
              className={styles['cls-1']}
              d="M970.73 514.83c-.2-1.48-.3-3-.6-4.44a2.9 2.9 0 0 1 1.75-3.67c6.87.1 10.76 3.8 13.68 9.86 3.3 6.84 7.9 13.06 11.42 19.82 6.08 11.66 12.67 23.1 18 35.06 5.47 12.18 12.37 23.82 15.63 37 1 3.88.82 7.8.9 11.7 0 1.27-.1 3-1.7 3.54s-2.67-.57-3.86-1.65c-5.07-4.63-7.67-11-11.52-16.42-5.75-8.17-9.44-17.5-14.43-26.1a288.2 288.2 0 0 1-13-26.35c-5.74-12.67-10.88-25.58-16.27-38.37z"
            />
            <path
              className={styles['cls-2']}
              d="M975.52 452q-7-11-14.1-21.92a30.15 30.15 0 0 1-3.52-7.54c-.58-1.9-.05-2.94 2.14-3.4 3.82-.83 6.78 0 9.1 3.24 1.6 2.26 3.55 4.28 5.34 6.42 8.58 10.87 17.37 21.58 25.68 32.65 7.84 10.45 16.53 20.2 24.63 30.4 3.65 4.63 7.46 9.1 10.33 14.28 2.38 4.28 4.52 8.55 2.56 13.8-.8 2.13-1.22 2.37-3.24 1.27-6.92-3.77-11.75-9.68-16.7-15.53-3.74-4.4-7.74-8.6-11.4-13.1-4.22-5.2-8.2-10.6-12.25-16q-9.3-12.28-18.58-24.57z"
            />
            <path
              className={styles['cls-3']}
              d="M765.56 718.22c-3.1-11.62-4.5-23.57-6.68-35.36-1.75-12.6-3.6-25.18-5.22-37.8a46 46 0 0 1 .18-13.15c.22-1.37 0-3.52 1.93-3.63 2.12-.12 4.47.08 5.65 2.55 1.56 3.3 2.17 6.85 2.84 10.36 3.18 16.5 6.5 33 9.22 49.55 2 11.85 5.1 23.57 4.57 35.8-.12 2.78-1.3 3.76-3.56 4.13s-4 .4-4.83-2.48a97.94 97.94 0 0 0-4.1-9.96z"
            />
            <path
              className={styles['cls-2']}
              d="M722.88 613.52c1.1 24.18 3 48.33 2.83 72.57 0 3-.72 5.22-3.8 6.18-2.4.76-3.84.15-4.63-2.53-3.38-11.43-4.53-23.2-5.18-35-.8-14-1.1-28.06-1.64-42.08-.3-7.34.53-14.6 1.43-21.84 2.5-.87 4.84.08 7.06.88 2.07.76 3 2.5 3.07 4.92.12 5.62.55 11.26.85 16.9z"
            />
          </g>
        </svg>
      </Plx>
    </div>
  )
}

export default Puzzle
