import React from 'react'
import Dialog from '@mui/material/Dialog'
import get from 'lodash/get'

import ImageAsset from '@components/image_asset'
import Markdown from '@components/markdown'
import { withLazyRef } from '@util/lazy_ref'

import FileIcon from '@components/icons/file'
import PlayIcon from '@components/icons/play'
import animations from '@components/icons/animated'
import styles from '@components/blocks/text_media_block.module.scss'

const AssetLink = ({ Icon, url, text, onClick }) => {
  return (
    <a
      href={url}
      className={styles.assetLink}
      data-link-type="text-media-block-asset"
      data-link-label={text}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon className={styles.assetLink__icon} />
      <span className={styles.assetLink__text}>{text}</span>
    </a>
  )
}

const VideoLink = ({ Icon, text, onClick }) => (
  <span className={styles.assetLink} onClick={onClick}>
    <Icon className={styles.assetLink__icon} />
    <span className={styles.assetLink__text}>{text}</span>
  </span>
)

const VideoLinkModal = ({ url, title, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: styles.videoLinkModal }}>
      <div className={styles.videoLinkModal__content}>
        <iframe src={url} frameBorder="0" allowFullScreen title={title} />
      </div>
    </Dialog>
  )
}

class TextMediaBlock extends React.Component {
  state = {
    videoModalOpen: false,
  }

  openModal = e => {
    e.preventDefault()
    this.setState({
      videoModalOpen: true,
    })
  }

  closeModal = () => {
    this.setState({
      videoModalOpen: false,
    })
  }

  getPageBackgroundColor = () => {
    return this.props.fields.pageBackgroundColor
  }

  render() {
    const fields = this.props.fields
    const classes = [styles.textMediaBlock]

    classes.push(styles['textMediaBlock--' + fields.layout.replace(/[\s,&()]/g, '')])
    classes.push(styles['textMediaBlock--' + fields.imageLocation.replace(/[\s,&()]/g, '')])

    if (fields.justification) {
      classes.push(
        styles['textMediaBlock--justify-' + fields.justification.replace(/[\s,&()]/g, '')]
      )
    }

    const animationName = fields.animation
    const header = fields.header
    const number = fields.number

    const videoLinkUrl = fields.videoLinkUrl
    const videoLinkText = fields.videoLinkText || videoLinkUrl
    const body = fields.body
    const downloadUrl = get(fields, 'download.file.url')
    const downloadText = fields.downloadText || downloadUrl

    if (!get(fields, 'media')) {
      classes.push(styles['textMediaBlock--noMedia'])
    }

    const Animation = animations[animationName]

    return (
      <div className={classes.join(' ')}>
        <div className={styles.textMediaBlock__text}>
          {number && <div className={styles.textMediaBlock__number}>{number}</div>}
          {header && <div className={styles.textMediaBlock__header}>{header}</div>}
          {body && <Markdown className={styles.textMediaBlock__body}>{body}</Markdown>}
          {downloadUrl && <AssetLink Icon={FileIcon} url={downloadUrl} text={downloadText} />}
          {videoLinkUrl && (
            <VideoLink Icon={PlayIcon} text={videoLinkText} onClick={this.openModal} />
          )}
          {videoLinkUrl && (
            <VideoLinkModal
              url={videoLinkUrl}
              open={this.state.videoModalOpen}
              onClose={this.closeModal}
              title={videoLinkText || 'Video'}
            />
          )}
        </div>
        <div className={styles.textMediaBlock__media}>
          <ImageAsset asset={fields.media} width="400" />
          {Animation && <Animation />}
        </div>
      </div>
    )
  }
}

export default withLazyRef(TextMediaBlock)
