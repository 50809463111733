import React from 'react'
import Plx from 'react-plx'
import classNames from 'classnames'

import styles from '@components/icons/animated/see_saw.module.scss'

const SeeSaw = ({ className, ...rest }) => {
  const plxData = [
    {
      start: 'self',
      startOffset: '20vh',
      duration: 150,
      name: 'see-saw-opacity',
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
      ],
    },
    {
      start: 'self',
      startOffset: '50vh',
      duration: 113,
      name: 'see-saw-rotation',
      properties: [
        {
          startValue: 0,
          endValue: -5,
          property: 'rotate',
        },
      ],
    },
  ]

  return (
    <Plx className={styles.seeSawAnimation} parallaxData={plxData}>
      <svg
        viewBox="0 0 1140 900"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames(styles.seeSawAnimation__svg, className)}
        {...rest}
      >
        <g>
          <path
            className={styles['cls-1']}
            d="M999.92 757.64L906.3 711.5Q876.63 697 847 682.57q-107.74-52.48-215.47-105c-12.9-6.3-25.7-12.78-38.55-19.18-20.17-9.38-40.26-18.95-59.95-29.3-2.6-.32-4.72-1.84-7-2.94q-25-12.1-49.9-24.3c-2.28-1.12-131-60.2-133.66-60.28l-42.26 29.54c-1.78 0-2.9 1.24-4.18 2.25l-31.47 25.27c-1.2 1-2.92 1.6-2.7 3.92.83.5 1.77 1.17 2.8 1.67l164.8 81.07 28.67 14.52A285.56 285.56 0 0 1 493 617c13.06 5.32 25.43 12.08 38.13 18.16 7.25 3.48 14.8 6.42 21.44 11.12a31.67 31.67 0 0 1 3.9 1.34q51.5 25.24 103 50.55a18 18 0 0 1 1.75 1.12c11.44 5.5 22.9 11 34.3 16.53q47.35 23.2 94.65 46.52c2.25 1.1 4.8 1.78 6.52 3.8 5.85 1.46 10.9 4.73 16.23 7.35 8 3.94 16.26 7.46 23.9 12.14L872 802.4l31.5 16.25c4.67 2 9.44 3.8 14 6 18.08 8.9 36.1 17.95 54.16 26.9 4.9 2.44 5.1 2.54 8.73-1.5 5.87-6.52 11.47-13.3 17.27-19.9 11.15-12.67 22.38-25.27 33.52-38 3.9-4.44 7.63-9.04 11.9-14.1-14.76-7.16-28.5-14.7-43.18-20.4z"
          />
          <path
            className={styles['cls-2']}
            d="M743.5 710.6c-35.5-17.05-70.37-35.36-105.92-52.28-34.7-16.52-68.68-34.48-103.37-51-34.48-16.42-68.42-33.93-102.7-50.78-1.7-.84-99.05-48.24-149.3-72l-17.64 14.17c-1.2 1-2.92 1.6-2.7 3.93.83.5 1.77 1.17 2.8 1.67l164.8 81.07 28.23 14.3a3.16 3.16 0 0 1 .43.22q2.26.9 4.5 1.84c6 2.53 11.8 5.34 17.56 8.3 4.3 2.24 8.57 4.57 12.8 7 8.16 3.33 16.06 7.22 23.93 11.16 4.72 2.36 9.44 4.74 14.2 7 4.53 2.17 9.2 4.14 13.66 6.45q1.33.7 2.65 1.44c1.46.82 2.9 1.7 4.3 2.66.27.2.55.37.83.56l.68.2 1.3.4a14.46 14.46 0 0 1 1.64.6l.28.13q51.5 25.24 103 50.55l.45.27c.44.27.87.57 1.3.86l17.17 8.24q8.58 4.12 17.14 8.3 23.68 11.6 47.34 23.23t47.3 23.3c.57.27 1.15.52 1.73.77a18 18 0 0 1 3.37 1.74 7.63 7.63 0 0 1 1.43 1.3c.73.2 1.45.4 2.16.63 5 1.63 9.42 4.42 14.08 6.72 4 2 8.08 3.84 12.1 5.8 3 1.46 6 3 8.9 4.63q1.47.83 2.9 1.7c5.84 2.56 11.92 4.68 17.24 8.22l17.92 8.5 31.5 16.25c4.67 2 9.44 3.8 14 6 18.08 8.9 36.1 17.95 54.16 26.9 4.9 2.44 5.1 2.54 8.73-1.5 4.84-5.37 9.5-10.9 14.23-16.4-30-14.17-121.4-59.1-122.77-59.77-42.8-21.1-85.37-42.63-128.35-63.28z"
          />
          <path
            className={styles['cls-3']}
            d="M417.4 408.35c3.82 11.67 10.1 22.16 16 32.83 3.4 6.2 3.57 6.1-2 10a19.8 19.8 0 0 0-1.54 1.34 134.92 134.92 0 0 1-37 5.28c-30.8-.16-61.57 2.55-92.37.4-1.94-2.43-2.3-5.42-2.84-8.32a230.63 230.63 0 0 1-3.47-29.2c.3-7.4.48-14.82 1-22.2.25-4-.57-6.38-4.95-7.6-7.88-2.2-14.82-6.5-21.6-11-4.68-3.13-4.67-3.08-3-8.2.87-2.6 2.37-5.06 2.24-8 8.07-12.85 13.17-27.1 19.05-41 3.1.6 5.16 3 7.66 4.58 9.38 6 19.82 8.63 30.66 10.13 2.76 8 7.53 14.6 13.36 20.72 9.35 9.8 30.76 9 38.92-.82a48.33 48.33 0 0 0 10.58-22.13c10-2.38 18.33-7.86 25.92-14.6 1.87-1.65 3.6-3.5 6-4.46 4.25 6.07 8.16 12.4 12.84 18.12 4.22 5.16 7.2 11.33 12.5 15.6 2.94 5.34 3.2 5.72-1.34 9.93a141 141 0 0 1-29.36 20.64c-5.24 2.8-5.52 2.77-4 8.42 1.7 6.7 2.74 13.64 6.72 19.53z"
          />
          <path
            className={styles['cls-4']}
            d="M388.12 335.22a48.33 48.33 0 0 1-10.58 22.13c-8.15 9.85-29.57 10.62-38.92.82-5.83-6.1-10.6-12.72-13.36-20.73a34.05 34.05 0 0 0 6.06-1.08c2.62-1 3.17-3.37 1.36-5.54a16.62 16.62 0 0 0-3.7-3.06 49.8 49.8 0 0 1-14.77-14.3 12.34 12.34 0 0 0-7.57-5.58 12.88 12.88 0 0 1-4.8-2.62c-8.37-6.6-9.13-12.7-2.63-21.15 5.65-.6 10.67.63 14.07 5.5 1.92 2.74 3.8 3.22 7 2.1s4.1-3 4-6.2c-.13-5.4.77-10.64 4.52-14.87 3.37-3.82 6.72-7.63 8.8-12.35 1-2.3 2.5-2.4 4.4-1.1a38.13 38.13 0 0 0 3.58 2.12c16.16 8.4 32.42 10.18 48.88.78a28.18 28.18 0 0 1 3.82-1.52c4.6 10.3 4.46 21.14 2.94 31.94-1.8 12.9-6.76 24.47-16.06 33.9a13.8 13.8 0 0 0-1.28 1.65c-4.25 5.95-3.3 8.06 4.24 9.17zM350 305.05c1.66-.38 3.26-.63 4.8-1.1 8.6-2.68 16.72-6.6 25-10.17a12.24 12.24 0 0 0 4.15-3.56c2.07-2.5 1.6-4.8-1.52-5-5.7-.38-8.56-3.66-10.84-8.15a5.5 5.5 0 0 0-.9-1.06c-1-1.08-2.2-2-3.6-1.16s-1 2.4-.26 3.65c1.28 2.18 2.62 4.34 3.92 6.52 1 1.73 2.87 3.35 1.5 5.62-1.62 2.67-4.37 2.23-6.87 2a20.7 20.7 0 0 1-3.95-1.25 5.67 5.67 0 0 0-5.32.52c-3.7 2.74-7.4 5.53-10.93 8.48-1.76 1.46-1 2.88.87 3.63a36.23 36.23 0 0 0 3.93 1.02zm28.7-6.6c-7.3 3.87-14.92 7.63-23.25 9.6-2.53.6-2.5 2.45-1 4.23a9.6 9.6 0 0 0 10.55 3.48c7.58-2 12-7.35 14.82-14.36.34-.86.18-1.82-1.08-2.94zm-29.45-12a6 6 0 0 0-5.9-6.2c-2.88.1-5.35 2.9-5.33 6 0 3.47 2.8 6.38 5.94 6.23 3.04-.1 5.28-2.7 5.33-6.03zm40.47-10.93c-.13-3.7-2.15-5.6-5.68-5.33-2.8.2-5.06 2.34-5 4.72 0 3.06 3.32 6.4 6.23 6.36s4.62-2.16 4.5-5.77zm-18.25 49.25c3.3 0 5.74-.56 7.2-3.06.85-1.45 1.23-3.07.17-4.55-1.22-1.7-2.93-1.14-4.35-.37a39 39 0 0 0-6.4 4.06c-1.26 1-.86 2.64.66 3.37a9.27 9.27 0 0 0 2.76.57z"
          />
          <path
            className={styles['cls-5']}
            d="M287 322.74c-5.88 13.88-11 28.12-19.06 41-3.4-1.2-5.8-3.82-8.37-6.1-30.86-27.38-49.3-61.63-56.28-102.17a130.4 130.4 0 0 1-1.55-25.67 31.42 31.42 0 0 1 5.5-16.3c5.6-8.67 15.65-8.25 20.42.9 3.34 6.37 5.15 13.38 7.38 20.22 7.6 23.22 16.33 45.93 31.35 65.6a107.9 107.9 0 0 0 14 15.48c2.3 2.12 4.9 4.17 6.6 7.04z"
          />
          <path
            className={styles['cls-6']}
            d="M937.1 645.9l19.78 7.75c1.68.66 3.26 1.75 5.2 1l-.07-.1c-.08.82.55 1 1.1 1.35 1.9 4.52.38 9.14.36 13.7 0 5.46.28 5.8 5.85 5.9 8.7.17 15.5-3.82 21.45-9.7 7.18 3.6 15.24 4.48 22.65 7.33.05 4.32.1 8.64.17 13 .1 5.88.35 11.75 2.15 17.42.13 2.08.28 4.16.38 6.24.23 5.13.26 5.44-4.86 6.05-3.06.36-3.74 1.65-4 4.35-.78 8.5-.92 17.14-3.63 25.38-.28.87-.68 1.7-1 2.55a13.93 13.93 0 0 1-8.68-1.73c-8-4.18-16.05-8.2-23.94-12.54-10.12-5.58-20.12-11.35-29.24-18.56-2.3-1.82-4-3.7-4.76-6.5 1.6-6.48 1.2-13.2 2.2-19.75.5-3.25-.77-5.8-3.26-7.84-2.1-1.73-4.18-3.5-6.27-5.28 1.43-2.17 2-4.7 2.6-7.14l5.86-22.88z"
          />
          <path
            className={styles['cls-5']}
            d="M445.36 349.87c-5.3-4.28-8.28-10.45-12.5-15.6-4.67-5.72-8.6-12-12.84-18.12 8.7-7.6 17.34-15.2 24.33-24.57a85.35 85.35 0 0 0 15.1-32.48c2.64-11.53 3.58-23.3 5.12-35a38.65 38.65 0 0 1 2.57-10.05 13.63 13.63 0 0 1 12.44-8.64c5.74-.32 10.12 2.33 13 7.6 2.77 5.04 3.65 10.63 4 16.05 1 16.4 1.3 32.9-2.43 49.07-2.73 11.8-7.9 22.57-14.5 32.82-8.47 13.17-19.26 24.27-29.9 35.56a33.4 33.4 0 0 1-4.4 3.37z"
          />
          <path
            className={styles['cls-7']}
            d="M935.95 708.8c.73 2.8 2.45 4.67 4.76 6.5 9.13 7.2 19.13 13 29.25 18.55 7.88 4.35 16 8.37 23.94 12.54a13.93 13.93 0 0 0 8.68 1.72c.6 3.6-2.12 6.25-2.65 9.52-11.76 18.3-29.4 29.5-47.87 39.72a22.46 22.46 0 0 1-3.1 1.5c-3.4 1.25-6.62 5.42-9.78 4-2.88-1.3-4.43-5.6-6.5-8.63a27.4 27.4 0 0 1-1.58-3.06c1.45-2.63-.5-4.4-1.68-6.3q-10.32-16.5-20.78-32.87a25.06 25.06 0 0 0-2.74-3.08c3.13-3.2 7.4-4.57 11.1-6.9 6.12-3.88 6.6-5 3.05-11.55a93.27 93.27 0 0 0-13.77-18.93c.75-3.3 3.43-2.45 5.57-2.5 8.04-.16 16.07-.18 24.1-.24z"
          />
          <path
            className={styles['cls-6']}
            d="M398.34 258.57a28.18 28.18 0 0 0-3.82 1.52c-16.46 9.4-32.72 7.6-48.88-.8a38.13 38.13 0 0 1-3.57-2.12c-1.9-1.27-3.38-1.2-4.4 1.1-2.1 4.73-5.44 8.54-8.82 12.36-3.75 4.23-4.65 9.48-4.52 14.88.07 3.18-.74 5-4 6.18s-5.1.65-7-2.1c-3.4-4.86-8.43-6.1-14.07-5.48a62.83 62.83 0 0 1-1.6-24.8c1.6-12.1 8.24-21.06 18.84-26.73 5.5-2.95 10.46-6.47 15.6-9.9 13.85-9.26 28.58-9.53 43.3-1.67 3.46 1.85 6.56 4.35 10 6.32 6.53 3.77 8.6 2.95 10.7-3.93 8.3 5.93 11 19.6 5.85 29.2-1.2 2.02-2.44 3.97-3.6 5.95zM959.36 607c-.3-12.75 10.48-23.84 24-24.73a54.9 54.9 0 0 1 17.9 1.65c3.36 2.37 7.5 3.4 10.36 6.93 9.07-9.65 20.1-11.87 32.18-10.8 2.46.22 6 1 6.4-2.2s-3.43-3-5.78-3.6c-1.3-.34-2.75.6-4.1-.53-.4-2.2 1.14-3.18 2.72-4.1 8.18-4.8 20-2.65 25.92 4.73 4.74 5.87 4.14 15.5-1.2 21.06-6.08 6.35-13.56 7.5-21.75 6.1-3.2-.56-6.37-1.2-9.53-1.9a33.65 33.65 0 0 0-11.07-.47c-5.13.56-5.74 1.8-3.85 6.8 5.74 15.16-.3 34.72-13.3 43.2-1.07-2.18.2-4 .9-5.93a45.1 45.1 0 0 0 2.6-7.9c.92-5.42-2.66-8.5-7.94-7.06a5.35 5.35 0 0 0-3 1.6c-2.3 3.54-4.63 1.95-7 .23-8-5.87-14.62-12.84-18.43-22.18-1.38-3.4-2.7-6.8-4.1-10.42-5.35 1.7-8.42 5.87-11.9 9.52z"
          />
          <path
            className={styles['cls-8']}
            d="M905.9 748.88a25.05 25.05 0 0 1 2.74 3.08q10.45 16.42 20.77 32.93c1.18 1.87 3.14 3.65 1.7 6.28-4.3 1-8.58 2-12.9 2.88a8.94 8.94 0 0 0-6.94 6.06c-2.44 6.25-5.2 12.37-7.82 18.54-4.2 5.3-6.56 11.92-11.73 16.55-2.06-1.47-4.07-3-6.2-4.4-5.24-3.36-10.54-6.65-15.8-10 1-6.1 2.3-12.18 2.25-18.4a226.13 226.13 0 0 0 5-26.54c2.08-6.4 4-12.9 10-17s11.84-8.17 18.95-9.98zM1015.72 703.54c-1.8-5.67-2-11.54-2.15-17.42-.08-4.32-.1-8.64-.17-13 9.23-.25 18.53.74 27.6-2.07 7.63-2.37 14.66-5.67 20.26-11.58 3.24-3.42 5.94-7.08 6.85-11.85a12.48 12.48 0 0 1 2.9-6.24c1.32-1.46 3.37-2.87 4.8-1.47 3.3 3.22 4.8.5 6.73-1.34a13.12 13.12 0 0 1 4.53-3.15c5.42-2 9.92.8 10.36 6.58.47 6.17-1.22 11.95-3.83 17.46-7.54 15.9-19.95 27-34.9 35.68-13.42 7.88-27.97 9.15-42.98 8.42zM959.36 607c3.5-3.62 6.56-7.77 12-9.52 1.42 3.6 2.72 7 4.1 10.4 3.8 9.34 10.46 16.32 18.44 22.2 2.34 1.7 4.65 3.3 7-.24a5.35 5.35 0 0 1 3-1.6c5.28-1.43 8.86 1.64 7.95 7.06a45.1 45.1 0 0 1-2.6 7.9c-.73 1.94-2 3.75-.92 5.94a28.4 28.4 0 0 1-9.76 9.32c-3.13 1.94-6 4.07-7.78 7.4-6 5.87-12.77 9.86-21.45 9.7-5.57-.12-5.87-.45-5.85-5.9 0-4.57 1.53-9.2-.37-13.7 0-.72-.3-1.25-1.08-1.34l.08.1c-4.5-7.3-8.74-14.7-9.48-23.5-.15-1.85-1.66-2.57-2.76-3.74-4-4.27-3.66-9 1.42-11.44 4.17-2.04 6.25-5.26 8.04-9.04zm9.7 27.83v-.1a47.15 47.15 0 0 0-5.48-.3 2.6 2.6 0 0 0-2.7 3.2c.4 4.04 4.78 9.53 8.7 10.76 4.26 1.32 7.2-.6 7.7-5.07.77-7 0-8-6.86-8.43zm4.93-13.5c.23-2.32-2.16-5.24-4.47-5.16-2.7.1-4 1.93-4.5 4.38-.5 2.64 1.52 5.37 3.9 5.5s4.8-2.28 5.07-4.76z"
          />
          <path
            className={styles['cls-6']}
            d="M869.7 820.8c5.27 3.33 10.57 6.62 15.8 10 2.14 1.38 4.15 2.94 6.22 4.4a30.15 30.15 0 0 1-5.45 15.5c-2.35 3.48-5.55 4.84-9.57 3.94-12.32-2.76-22.12-9.3-28.48-20.3-2.46-4.24-3.8-9.3-.45-13.73s8.74-3.62 13.5-2.84c2.9.47 5.62 2 8.43 3.05z"
          />
          <path
            className={styles['cls-7']}
            d="M300.5 458.23c30.8 2.13 61.57-.57 92.36-.4a134.92 134.92 0 0 0 37-5.3c2.3 3.85 5.8 6.6 8.95 9.64 9.86 9.47 17.94 20.5 27.36 30.36a9.28 9.28 0 0 1 2.83 6.9 42.4 42.4 0 0 1-5.9 1.92c-13.18 2.34-26.4 4.34-39.85 4.18-3 0-6-.18-9 .05-3.28.26-4 1.47-2.53 4.52 3.06 6.27 4.54 13 6.54 19.65l-.18.33.28.26v2.33a70.67 70.67 0 0 1-12 7.86c-8.73 4.55-17.28 9.44-25.8 14.36-2.07 1.2-4.75 2-5.3 5a15.15 15.15 0 0 0-6.58 1.4c-4.87 2.07-5.24 1.86-7.8-3-1.7-3.24-3.28-6.52-4.9-9.78l-1.25-1.13.1.08c-.94-3.58-3.68-5.8-6.27-8.05-3-2.57-6.13-4.9-9.28-7.25-6.47-4.84-13.47-9.1-19.38-14.52-13.48-12.4-19.55-28.25-19.68-46.46z"
          />
          <path
            className={styles['cls-4']}
            d="M407.4 654.7c-7.05-13-15.17-25.35-20.7-39.13-3.27-8.15-5.07-16.73-7.9-25-1.76-10.16-.68-20.64-3.54-30.67.53-2.95 3.2-3.8 5.28-5 8.52-4.92 17.07-9.8 25.8-14.36a71.08 71.08 0 0 0 12-7.86 44 44 0 0 1 3.43 10.4c.92 3.75.58 7.78 2.5 11.3.5 10.52 3.23 20.67 5.13 31 .2 8.54 2.56 16.8 3.37 25.26 1.1 11.42 2.47 22.8 3.73 34.22-9.98 2.33-19.97 4.8-29.1 9.83z"
          />
          <path
            className={styles['cls-6']}
            d="M407.4 654.7c9.13-5 19.12-7.5 29.13-9.88 6.16.4 12.5-.68 18.36 2.28 5 2.53 8.87 5.74 8.66 12.07a11.78 11.78 0 0 1-5.1 10c-9 6.5-19.36 8.28-30 9.23-3.66.33-7.37 0-11.06 0a8 8 0 0 0-4.1-1.53c-5.16-6.57-7.97-13.73-5.9-22.18z"
          />
          <path
            className={styles['cls-8']}
            d="M937.1 645.9c-2 7.62-3.9 15.25-5.9 22.86-.63 2.46-1.15 5-2.58 7.14-14.4-10.14-24.8-23.25-30.85-40s-6.6-33.93-4-51.32a37.14 37.14 0 0 1 2.6-8.6 10.34 10.34 0 0 1 8.93-6.75c4.34-.47 6.83 2 9.07 5.2 3.2 4.62 3.63 9.95 4.1 15.24.33 3.92.5 7.87.5 11.8a60.67 60.67 0 0 0 18.14 44.43z"
          />
          <path
            className={styles['cls-6']}
            d="M836.78 785.62v4.87c-.28 6.67-3.2 9.58-9.75 8.37a57.63 57.63 0 0 1-29-14.22c-5.24-4.8-7-13.3-1.4-18.52 5.87 1.45 10.92 4.72 16.25 7.34 8 3.95 16.25 7.47 23.9 12.15z"
          />
          <path
            className={styles['cls-4']}
            d="M456.43 618.46c-.53-6.3.76-12.42 1.68-18.6A285 285 0 0 1 493 617c-2.12 4-5 7.47-7.75 11l-4.54-1.63c-8-2.9-15.63-6.97-24.27-7.9z"
          />
          <path
            className={styles['cls-6']}
            d="M350 305.05a36.23 36.23 0 0 1-3.9-1.05c-1.9-.75-2.62-2.17-.87-3.63 3.54-3 7.22-5.74 10.93-8.48a5.67 5.67 0 0 1 5.32-.54 20.7 20.7 0 0 0 3.94 1.25c2.5.28 5.25.72 6.87-2 1.36-2.25-.48-3.87-1.5-5.6-1.3-2.2-2.65-4.35-3.93-6.54-.73-1.26-1.24-2.74.26-3.65s2.62.1 3.6 1.18a5.5 5.5 0 0 1 .9 1.06c2.27 4.5 5.12 7.77 10.83 8.15 3.12.2 3.6 2.5 1.52 5a12.24 12.24 0 0 1-4.16 3.55c-8.24 3.58-16.36 7.5-25 10.16-1.5.52-3.1.78-4.82 1.15zM378.74 298.46c1.2 1.1 1.42 2.07 1.08 2.94-2.77 7-7.24 12.34-14.82 14.36a9.6 9.6 0 0 1-10.54-3.47c-1.47-1.8-1.5-3.64 1-4.24 8.37-1.98 15.98-5.73 23.28-9.6zM349.3 286.45c-.06 3.32-2.33 5.92-5.3 6.06-3.15.16-5.92-2.75-5.94-6.2 0-3.17 2.45-6 5.33-6a6 6 0 0 1 5.9 6.15zM389.76 275.5c.13 3.62-1.5 5.72-4.48 5.77s-6.18-3.3-6.23-6.36c0-2.37 2.22-4.5 5-4.72 3.56-.27 5.58 1.62 5.7 5.33zM371.52 324.77a9.27 9.27 0 0 1-2.7-.57c-1.54-.73-1.94-2.33-.68-3.37a39 39 0 0 1 6.4-4.06c1.42-.77 3.13-1.34 4.35.37 1.05 1.48.68 3.1-.18 4.56-1.46 2.5-3.9 3.06-7.2 3.07zM969.05 634.8l1.37.07c6.8.46 7.63 1.47 6.85 8.43-.5 4.46-3.43 6.4-7.7 5.06-3.9-1.22-8.3-6.7-8.7-10.77a2.6 2.6 0 0 1 2.7-3.2 47.15 47.15 0 0 1 5.48.3zM974 621.3c-.26 2.47-2.8 4.83-5.08 4.7s-4.38-2.85-3.9-5.5c.47-2.44 1.82-4.27 4.5-4.37 2.32-.13 4.7 2.87 4.48 5.16zM456.43 618.46c8.64.94 16.26 5 24.27 7.93l4.54 1.62c2.87 1.92 5.7 3.9 8.6 5.74a29.68 29.68 0 0 1 7.24 6.4c4.62 5.7 3.32 11.5-3.32 14.7-4.24 2-8.55 2.9-13.38 1.6-7.86-2.12-15.4-4.86-22.2-9.34-12.05-7.9-13.75-16.64-5.75-28.66z"
          />
        </g>
      </svg>
    </Plx>
  )
}

export default SeeSaw
